module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src3016303288/src/gatsby-mofs/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://muiroralsurgery.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Muir Oral, Facial, & Dental Implant Surgery","lang":"en","display":"standalone","short_name":"Muir Oral, Facial, & Dental Implant Surgery","description":"Dr. Nestor Karas is a board-certified oral surgeon who offers dental implants, wisdom teeth removal, full-arch restoration, orthognathic surgery, and more in Walnut Creek, California.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/favicon-512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"442cfec1ee45b6b950a8812562ae2597"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-120},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5C66QRJ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
